import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

function useCurrentQuery() {
  const route = useRoute()

  const activeQuery = ref(route.query)

  watch(
    () => route.query,
    newQuery => {
      activeQuery.value = newQuery
    },
    { deep: true }
  )

  return activeQuery
}

export default useCurrentQuery
