import { CarPremiumFeatures, CarProfile } from './carProfile'
import { User } from './user'
import { DamageReport } from './damageReport'
import { TripCheckIn } from './checkIn'
import { TripCheckOut } from './checkOut'
import { Review } from './review'

export enum TripStatus {
  INCOMPLETE = 'incomplete',
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DECLINED_BY_HOST = 'declined_by_host',
  DECLINED_BY_RENTER = 'declined_by_renter',
  CANCELLED_BY_HOST = 'cancelled_by_host',
  CANCELLED_BY_RENTER = 'cancelled_by_renter',
  ON_HOLD = 'on_hold',
  ONGOING = 'ongoing',
  FINISHED = 'finished',
}

export enum TripCancellationRequestStatus {
  OPENED = 'opened',
  RESOLVED = 'resolved',
}

export interface TripCancellationRequest {
  reason: string
  id: string
  admin_comment: string | null
  trip_id: string
  user_id: string
  status: TripCancellationRequestStatus
  createdAt: string
  updatedAt: string
}

export interface Trip {
  cancel_policy_script: string | null
  is_promotional_admin_fee?: boolean
  promotional_admin_fee?: number
  deposit?: number | null
  car_profile_insurance_request_ids: string[]
  innerInsurance?: {
    id: string
    car_profile_insurance_request_id: string
    policy_number: string | null
    policy_id: string | null
    total_premium: number | null
    created_at: string
    updated_at: string
    trip_id: string
  }
  id: string
  commercial_host: boolean
  status: TripStatus
  decline_reason: string
  host_id: string
  renter_id: string
  start_date: string
  end_date: string
  discount: string
  car_profile_id: string
  rental_fee: number
  daily_price: number
  weekly_price: number
  monthly_price: number
  car_address: string
  pickup_address_place_id: string
  pickup_address_name: string
  pickup_address_price: string
  dropoff_address_place_id: string
  dropoff_address_name: string
  dropoff_address_price: string
  cancellation_policy_type: string
  daily_distance_limit: number
  extra_distance_charge: number
  insurance_fee: number
  total_insurance: number
  admin_fee: number
  effective_admin_fee: number
  rent_duration: number
  total: number
  toc_accepted: boolean
  message_to_host: string
  host_profit: number
  admin_profit: number
  created_at: string
  updated_at: string
  transactions: TripTransaction[]
  payment: TripPayment
  extra: null | TripExtra
  waybill_sent: boolean
}

export interface TripExtra {
  id: string
  trip_id: string
  admin_id: string
  odometer_before: number
  odometer_after: number
  odometer_limit: number
  actual_traveled: number
  extra_charge: number
  created_at: string
  updated_at: string
}

export interface TripPayment {
  created_at: string
  deposit_charge: number
  deposit_freeze: number
  deposit_reverse: number
  id: string
  service_charge: number
  service_freeze: number
  service_reverse: number
  status: string
  trip_id: string
  updated_at: string
}

export interface TripTransaction {
  order_id: string
  order_status: string
  pan: null | string
  payment_card_id: string
  payriff_type: string
  session_id: string
  trip_id: string
  type: string
  updated_at: string
  wallet_id: string
  action: string
  admin_id: null | string
  amount: number
  amountScr: number
  amount_scr: string
  created_at: string
  currency: string
  currency_code: null | string
  description: string
  id: string
  internal_comment: string
  logs: TripTransactionLog[]
}

export interface TripTransactionLog {
  comment: string
  created_at: string
  id: string
  payriff_type: string
  transaction_id: string
  transaction_status: string
  updated_at: string
}
''
export interface TripWithRenter extends Trip {
  renter: User
}

export type ExtraJsonsKey = keyof Pick<
  CarPremiumFeatures,
  'chauffeur_service' | 'full_tank'
>

export interface TripExtended extends Trip {
  host: User
  renter: User
  car_profile: CarProfile
  extras_json: Array<{ name: ExtraJsonsKey; fee: number }> | null
}

export interface TripFull extends TripExtended {
  cancellation_requests: TripCancellationRequest[]
  damage_reports: DamageReport[]
}

export interface TripFullWithRelations extends TripFull {
  checkIns: TripCheckIn[]
  checkOuts: TripCheckOut[]
  reviews: Review[]
}

export interface FinishTripPaymentPayload {
  action: 'charge' | 'freeze' | 'unfreeze' | 'refund'
  payload: {
    transactionType: 'service' | 'deposit'
    amount: number
    comment: string
    internal_comment?: string
  }
}
